<template>
  <div class="vh-100 outer">
    <div class="h-100 m-0 p-0 d-flex align-items-center justify-content-center">
      <div class="auth-container text-center">
        <img src="@assets/images/connect-business.png" alt="Connect Google Account" class="rounded-lg" />
        <h2 class="d-block my-4">
          Connect a Google My Business Account
        </h2>
        <div class="my-2">
          Connect your Google My Business and we will generate the perfect response for your reviews.
        </div>
        <a v-if="user && business" :href="gRedirectLink">
          <div class="google-btn">
            <div class="google-icon-wrapper">
              <img class="google-icon" src="@assets/images/Google__G__logo.png"/>
            </div>
            <p class="btn-text"><b>Connect Google Account</b></p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      user:null,
      business:null
    }
  },
  computed: {
    config() {
      return this.$store.getters['app/config']
    },
    gRedirectLink(){
      const apiUrl = (process.env.NODE_ENV === 'production' ? window.location.origin.includes('staging.') ? 'https://stagingapi.replyengine.io/' : 'https://api.replyengine.io/' : 'https://stagingapi.replyengine.io/') + 'api/v1';
      return apiUrl+'/auth-redirect/'+this.user.id+'/'+this.business.id;
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.business = Vue.util.extend({}, this.user.business);
    if(!this.user || this.user && this.user.business === null){
      this.$router.push({name:'business-notfound'});
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
  .outer{
    margin-top: -60px;
  }
  $white: #fff;
  $google-blue: #4285f4;
  $button-active-blue: #1669F2;

  .google-btn {
    width: 225px;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    margin:20px auto;
    position: relative;
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: $white;
    }
    .google-icon {
      position: absolute;
      width: 18px;
      height: 18px;
      left:calc(50% - 9px);
      top:calc(50% - 9px)
    }
    .btn-text {
      float: right;
      margin: 11px 11px 0 0;
      color: $white;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
    }
    &:hover {
      box-shadow: 0 0 6px $google-blue;
    }
    &:active {
      background: $button-active-blue;
    }
  }
</style>
